import FullBleedHero from '@/components/Sections/FullBleedHero'
import FullBleedQuestionnaire from '@/components/Sections/FullBleedQuestionnaire'
import SplitHero from '@/components/Sections/SplitHero'

const DynamicHero = ({ fields }) => {
  const { heroType, fullBleedHero, splitHero, questionnairetHero } = fields

  if (heroType === 'hero--full') {
    return <FullBleedHero imagePriority={true} fields={fullBleedHero} />
  } else if (heroType === 'hero--questionnaire') {
    return <FullBleedQuestionnaire fields={questionnairetHero} />
  } else {
    return <SplitHero imagePriority={true} fields={splitHero} />
  }
}

export default DynamicHero
